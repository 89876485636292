const theme = {
  colors: {
    background: {
      dark: "#141425",
      light: "#29264F",
    },
    text: {
      dark: {
        white: "#FFFFFFDE",
        gray: "#FFFFFF99",
        black: "#FFFFFF61",
      },
    },
    primary: {
      cyan: "#19FBFF",
      blue: "#1FA6FF",
      purple: "#896BFF",
      pink: "#FF2CFB",
      yellow: "#F7CE58",
      orange: "#F09343",
    },
    secondary: {
      green: "#C3ECBF",
      blue: "#B8DFE6",
      purple: "#C5BBDF",
      pink: "#FBB9C5",
      yellow: "#FBFBE4",
      orange: "#FDD0B1",
    },
  },
};

export default theme;
