import { lazy, Suspense } from "react";

const Hero = lazy(() => import("src/components/sections/Hero"));
const Work = lazy(() => import("src/components/sections/Work"));
const Projects = lazy(() => import("src/components/sections/Projects"));
const Contact = lazy(() => import("src/components/sections/Contact"));
const More = lazy(() => import("src/components/sections/More"));
const Nav = lazy(() => import("src/components/base/Navbar"));

function IndexPage() {
  return (
    <div>
      <Suspense fallback={<div />}>
        <Nav />
        <Hero />
        <Work />
        <Projects />
        <More />
        <Contact />
      </Suspense>
    </div>
  );
}

export default IndexPage;
