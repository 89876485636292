import { css } from "styled-components";

const fonts = css`
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Space+Mono:wght@400;700&display=swap");

  h2 {
    font-family: "Space Mono", monospace;
  }

  body,
  h1,
  p,
  a {
    font-family: "Montserrat", sans-serif;
  }

  p {
    line-height: 32px;
  }
`;

export default fonts;
