import { createGlobalStyle } from "styled-components";
import fonts from "./fonts";
import TransitionStyles from "./TransitionStyles";

const GlobalStyles = createGlobalStyle`
${fonts}
${TransitionStyles}
  
  html {
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
    overflow-x: hidden; 
    overflow-y: auto; 
    max-width: 100vw; 
  }
  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    background-color: #141425;
    color: white;
    counter-reset: section; 
    overflow-x: hidden; 
    overflow-y: hidden; 
    max-width: 100vw; 
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body > div:first-of-type,
  div#__next,
  div#__next > div {
    height: 100%;
  }

  a {
    color: inherit; 
    text-decoration: none; 
    margin: 0;
    padding: 0; 
    background-color: transparent; 
  }
  
  a:hover,
  a:active,
  a:focus {
    color: inherit; 
    text-decoration: none;
    outline: 0; 
  }

  .numbered-heading {
    display: flex;
    align-items: center;
    position: relative;
    margin: 10px 0px 40px 0px;
    width: 100%;
    font-size: clamp(23px, 5vw, 32px);
    white-space: nowrap;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 1.1;

    &:before {
      counter-increment: section; 
      content: '0' counter(section) '.'; 
      bottom: 4px;
      margin-right: 10px;
      font-size: clamp(16px, 3vw, 20px);
      font-weight: 400;
      font-family: "Space Mono", monospace;
      @media (max-width: 480px) {
        margin-bottom: -3px;
        margin-right: 5px;
      }
    }

    &:after {
      content: '';
      display: block;
      position: relative;
      top: 0px;
      width: 300px;
      height: 1px;
      margin-left: 20px;
      @media (max-width: 1080px) {
        width: 200px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 600px) {
        margin-left: 10px;
      }
    }
  }
`;

export default GlobalStyles;
