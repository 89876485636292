import IndexPage from "./components/pages/index";

function App() {
  return (
    <div>
      <IndexPage />
    </div>
  );
}

export default App;
